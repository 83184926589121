import React, { useEffect, useState } from "react";
import { PDFDownloadLink } from "@react-pdf/renderer";
import DiscountsTableComponent from "../DiscountsTableComponent";
import { Row, Col, Button, Form } from "react-bootstrap";
import ExportPDFComponent from "../ExportPDFComponent";
import DragAndDropInspComponent from "./DragAndDropInspComponent";
import { useUser } from "../../../../../context/UserContext";
import { teams } from "../../../../../constants/teams";
import ExportPDFComponentPricing from "../ExportPdfComponentPricing";
import shortSnapshotFirebase from "../../../../../utils/ShortSnapshotFirebase";

export default function ResumenVirtualInspComponent({
  formData,
  formDataTableView,
  setFormDataTableView,
  saveFromDataString,
  handleLinkClick,
  editAllowed,
  deal_id,
  user,
}) {
  const [renderedData, setRenderedData] = useState([]);
  const [totalDiscount, setTotalDiscount] = useState(0);
  const [totalMandatoryDiscount, setTotalMandatoryDiscount] = useState(0);
  const [deal, setDeal] = useState(null);

  useEffect(() => {
    const callbackDeals = (data) => {
      setDeal(data);
    };
    const unsubscribeDeals = shortSnapshotFirebase(
      user?.company,
      "deals",
      deal_id,
      callbackDeals
    );
    return () => {
      unsubscribeDeals();
    };
  }, [deal_id, user?.company]);

  const handleChange = (key) => (event) => {
    const { value } = event.target;
    saveFromDataString("results", key, value);

    if (
      key === "requiresMechanicalRepair" ||
      key === "requiresSheetMetalAndPaint"
    ) {
      const requiresMechanicalRepair =
        key === "requiresMechanicalRepair"
          ? value
          : formData.results.requiresMechanicalRepair;
      const requiresSheetMetalAndPaint =
        key === "requiresSheetMetalAndPaint"
          ? value
          : formData.results.requiresSheetMetalAndPaint;

      const readyForSale =
        requiresMechanicalRepair === "No" && requiresSheetMetalAndPaint === "No"
          ? "Si"
          : "No";
      saveFromDataString("results", "readyForSale", readyForSale);
    }
  };

  useEffect(() => {
    saveFromDataString("results", "totalDemerits", totalDiscount);
  }, [totalDiscount]);

  return (
    <>
      <div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <p className="section-title">Resultados</p>
          <div style={{ textAlign: "center", marginTop: 10 }}>
            {totalDiscount !== 0 && (
              <PDFDownloadLink
                document={
                  user?.team === teams.PRICING || user?.team === teams.ADMIN ? (
                    <ExportPDFComponentPricing
                      tableData={renderedData}
                      detailsData={formData}
                      totalMandatoryDiscount={totalMandatoryDiscount}
                    />
                  ) : (
                    <ExportPDFComponent
                      tableData={renderedData}
                      detailsData={formData}
                      totalMandatoryDiscount={totalMandatoryDiscount}
                    />
                  )
                }
                fileName="detalle_observaciones.pdf"
              >
                {({ blob, url, loading, error }) => (
                  <Button onClick={() => {}}>Descargar PDF</Button>
                )}
              </PDFDownloadLink>
            )}
          </div>
        </div>
        <Row>
          <Col xs={6} lg={4}>
            <div className="mb-2">
              <label className="section-input-label mb-2">Estado general</label>
              <input
                disabled
                type="text"
                className="section-input"
                value={formData?.section7?.overallStatus?.values}
                name="estadoGeneral"
                onChange={handleChange("overallStatus")}
              />
            </div>
          </Col>
          <Col xs={6} lg={4}>
            <div className="mb-2">
              <label className="section-input-label mb-2">
                Deméritos totales
              </label>
              <input
                disabled
                readOnly
                className="section-input"
                value={`$${formData?.results?.totalDemerits?.toLocaleString(
                  "es-ES"
                )}`}
                name="demeritosTotales"
              />
            </div>
          </Col>
          <Col xs={6} lg={4}>
            <div className="mb-2">
              <label className="section-input-label mb-2">
                ¿Requiere reparación mecánica?
              </label>
              <Form.Select
                disabled={!editAllowed}
                className="section-input"
                name="requiereReparacionMecanica"
                value={formData.results.requiresMechanicalRepair}
                onChange={handleChange("requiresMechanicalRepair")}
              >
                <option value="">Seleccionar...</option>
                <option value="Si">Si</option>
                <option value="No">No</option>
              </Form.Select>
            </div>
          </Col>
          <Col xs={6} lg={4}>
            <div className="mb-2">
              <label className="section-input-label mb-2">
                ¿Listo para la venta?
              </label>
              <Form.Select
                disabled
                className="section-input"
                name="listoParaLaVenta"
                value={formData.results.readyForSale}
              >
                <option value="Si">Si</option>
                <option value="No">No</option>
              </Form.Select>
            </div>
          </Col>
          <Col xs={6} lg={4}>
            <div className="mb-2">
              <label className="section-input-label mb-2">
                Días de puesta a punto
              </label>
              <input
                disabled={!editAllowed}
                className="section-input"
                value={formData.results.daysOfSetUp}
                type="number"
                name="diasDePuestaAPunto"
                onChange={handleChange("daysOfSetUp")}
              />
            </div>
          </Col>
          <Col xs={6} lg={4}>
            <div className="mb-2">
              <label className="section-input-label mb-2">
                ¿Requiere chapa y pintura?
              </label>
              <Form.Select
                disabled={!editAllowed}
                className="section-input"
                name="requiereChapaYPintura"
                value={formData.results.requiresSheetMetalAndPaint}
                onChange={handleChange("requiresSheetMetalAndPaint")}
              >
                <option value="">Seleccionar...</option>
                <option value="Si">Si</option>
                <option value="No">No</option>
              </Form.Select>
            </div>
          </Col>

          <Col xs={6} lg={4}>
            <div className="mb-2">
              <label className="section-input-label mb-2">
                ¿Apto para consignación?
              </label>
              <Form.Select
                disabled={!editAllowed}
                className="section-input"
                name="aptoConsignacion"
                value={formData.results.apto_consignacion}
                onChange={handleChange("aptoConsignacion")}
              >
                <option value="">Seleccionar...</option>
                <option value="Si">Si</option>
                <option value="No">No</option>
              </Form.Select>
            </div>
          </Col>
        </Row>
        <div className="divider" style={{ marginTop: "16px" }} />
        <DiscountsTableComponent
          formDataTableView={formDataTableView}
          setFormDataTableView={setFormDataTableView}
          handleLinkClick={handleLinkClick}
          editAllowed={editAllowed}
          setRenderedData={setRenderedData}
          totalDiscount={totalDiscount}
          setTotalDiscount={setTotalDiscount}
          totalMandatoryDiscount={totalMandatoryDiscount}
          setTotalMandatoryDiscount={setTotalMandatoryDiscount}
        />
        <div className="divider" style={{ marginTop: "16px" }} />
        <Row>
          <div className="select-container">
            <label className="label-input">Comentarios</label>
            <textarea
              disabled={!editAllowed}
              className="inputModal mt-1"
              style={{ width: "100%", height: "110px" }}
              placeholder="Dejar comentarios adicionales"
              name="comentarios"
              value={formData.results.comments}
              onChange={handleChange("comments")}
            />
          </div>
        </Row>
        <div className="divider" style={{ marginTop: "16px" }} />
        <DragAndDropInspComponent editAllowed={editAllowed} />
      </div>
    </>
  );
}
